<template>
  <div class="whole">
    <Nav></Nav>
    <div class="postWhole minheight">
      <div class="post-position">您当前位置：稿件详情页</div>
      <div class="video-detail" v-if="postInfo.postType == 'VIDEO'">
        <div class="video-play">
          <div class="title">{{ postInfo.title }}</div>
          <video :src="postInfo.videoURL" :poster="postInfo.posterURL" controls loop width="1200" height="523"></video>
        </div>
        <div class="video-text">
          <div>作者：{{ postInfo.author }}</div>
          <div>视频ID：{{ postInfo.id }}</div>
          <div>上传时间：{{ postInfo.upTime }}</div>
          <div>地点：{{ newAddress }}</div>
          <div>类型：{{ newTypes }}</div>
        </div>
        <div class="video-btn" v-if="postInfo.canDownloadMiddlePic">
          <div class="v-b" @click="onVideoDownload(postInfo.id)">下载视频</div>
        </div>
        <div class="video-maintext">
          <div>正文:</div>
          <pre>{{ postInfo.mainText }}</pre>
        </div>
      </div>
      <div class="post-content" v-else>
        <div class="post-bigPic">
          <div class="post-info">
            <div class="post-title">{{ postInfo.title }}</div>
            <div class="post-other">
              <div>{{ postInfo.eventTime }} 作者：{{ postInfo.author }}</div>
              <div>图片分类：{{ newTypes }}</div>
              <div>事件地点：{{ newAddress }}</div>
            </div>
          </div>
          <div class="post-pic">
            <div class="post-left" v-if="postInfo">
              <div class="post-img" v-for="(k, showIndex) in postInfo.pics" :key="k.id" :class="{ active: swiperIndex == showIndex }">
                <div class="only-img" :style="{ backgroundImage: `url(` + k.imgURL + `)` }" @click="onLookPic(showIndex)"></div>
              </div>
            </div>
            <div class="post-right">
              <div class="pic-title">图片信息</div>
              <div class="pic-size" v-if="postInfo.pics">
                <div>图片ID：{{ postInfo.pics[swiperIndex].id }}</div>
                <div>图片作者：{{ postInfo.author }}</div>
                <div>拍摄时间：{{ postInfo.pics[swiperIndex].exifTime }}</div>
              </div>
              <div class="pic-other" v-if="postInfo.pics">
                <div>大小:{{ postInfo.pics[swiperIndex].size }}</div>
                <div>尺寸:{{ postInfo.pics[swiperIndex].width }} *{{ postInfo.pics[swiperIndex].height }}</div>
                <div>格式:{{ postInfo.pics[swiperIndex].exifModel }}</div>
              </div>
              <div class="pic-down">
                <div class="picdown">
                  <el-button type="danger" :disabled="!postInfo.downloadOri">
                    <div class="down-icon"></div>
                    <div class="number" @click="postInfo.downloadOri ? onDownNumber('downo') : function () {}">
                      <span>下载原图</span>
                      <span v-if="postInfo.downloadOri">({{ selectedLength }}张)</span>
                    </div>
                  </el-button>
                </div>
              </div>
              <div class="pic-share">
                <div @click="onCollect">收藏图片</div>
                <div @click="onGetShare">分享图片</div>
              </div>
            </div>
          </div>
          <div class="pic-list">
            <div class="pic-select">
              <el-checkbox class="selected" v-model="allCheck" @change="onAllSelected" :indeterminate="isIndeterminate">全部选中</el-checkbox>
            </div>
            <div class="pic-swiper">
              <div class="pic-show" :style="{ left: transformDistance + 'rem' }">
                <div class="pic-item" v-for="(p, litterIndex) in postInfo.pics" :key="p.id + '_pic'" ref="swiperpic" @click="onLookPic(litterIndex)">
                  <el-checkbox class="selected" v-model="p.selectedPic" @change="onChangeSelect" v-if="loginStatus"></el-checkbox>
                  <img
                    class="bg-img"
                    :src="p.sImgURL"
                    :class="{
                      active: swiperIndex == litterIndex,
                    }"
                    @click="onEnlarge(litterIndex)"
                  />
                </div>
              </div>
            </div>
            <div class="pic-text">{{ postInfo.pics[swiperIndex].text }}</div>
            <div class="pic-left">
              <div class="left-icon" @click="onLargeLeft">
                <div class="left-d el-icon-d-arrow-left"></div>
              </div>
            </div>
            <div class="pic-right">
              <div class="right-icon" @click="onLargeRight">
                <div class="right-icon" @click.stop="onLargeRight">
                  <div class="right-d el-icon-d-arrow-right"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="post-text">
            <div>摘要：{{ postInfo.mainText }}</div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>加入收藏失败，请使用Ctrl+D进行添加</div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <form :action="ooPicUrl" ref="ooPicForm" method="POST" target="_blank">
      <input type="hidden" name="picIds" :value="picIdsString" />
    </form>
    <Bottom></Bottom>
  </div>
</template>

<script>
var timers = []
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'
// import gohomePic from '../assets/replay_img.png'
// import QRCode from 'qrcode'
// import bShare from "";

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      postInfo: {}, //稿件信息
      newTypes: '', // 转换后的类型
      newAddress: '', // 转换后的地址
      isswiperform: false, // 点击移动一个
      isswipermRight: false,
      picIndex: 0,
      swiperIndex: 0, // 轮播的选中的哪一个
      selectedLength: 0,
      allCheck: false, //默认全选的状态
      selected: [], // 被选中的图片数组
      picIdsString: '',
      selectedPic: '',
      isswiperright: false, // 往右移动
      dialogVisible: false, //显示收藏的弹框
      isIndeterminate: false, // 半选的状态
      transformDistance: 1, // 查看大图时的小图的移动距离
      transformlargeDistance: 1, // 点击小图，中图的变化距离
      videoStatus: false, // 视频稿件的显示与隐藏
      loginStatus: false, // 是否登录的状态
      ooPicUrl: '', // 原图from地址
    }
  },
  created() {
    RD.pure()
      .user()
      .sync()
      .then(() => {
        this.loginStatus = true
      })
    console.log('status', this.loginStatus)
    this.reload()
  },
  methods: {
    reload() {
      var thiz = this
      // var shareUrl = window.location.href
      RD.post()
        .id(this.$route.query.id)
        .one()
        .then((data) => {
          var newArr = []
          data.eventTypeNamePaths.forEach((i) => {
            var type = []
            i.forEach((k) => {
              type.push(k)
            })

            newArr.push(type.join('>'))
          })

          thiz.newTypes = newArr.join('●')
          data.pics.forEach((k) => {
            k.imgURL = RD.pic().id(k.id).mImage_URL()
            k.sImgURL = RD.pic().id(k.id).sImage_URL()
            k.selectedPic = false
          })
          data.frontImg = RD.pic().id(data.frontPicId).mImage_URL()
          var newaddr = []
          data.eventAddrNamePath.forEach((k) => {
            newaddr.push(k)
          })
          thiz.newAddress = newaddr.join('>')
          if (data.publicForUser) {
            data.userHead = RD.userShow().id(data.userShow.id).headPic_URL()
          }
          if (data.postType == 'VIDEO') {
            thiz.videoStatus = true
            data.posterURL = RD.video().id(data.id).poster_URL()
            data.videoURL = RD.video().id(data.id).mvideo_URL()
          } else {
            thiz.videoStatus = false
          }
          thiz.postInfo = data
        })
    },
    onChangeSelect() {
      var thiz = this
      thiz.selected = []
      thiz.postInfo.pics.forEach((k) => {
        if (k.selectedPic == true) {
          thiz.selected.push(k.id)
        }
      })
      thiz.selectedLength = thiz.selected.length
      if (thiz.selected.length == thiz.postInfo.pics.length) {
        thiz.allCheck = true
        thiz.isIndeterminate = false
      } else if (thiz.selected.length == 0) {
        thiz.allCheck = false
        thiz.isIndeterminate = false
      } else if (thiz.selected.length >= 1) {
        thiz.isIndeterminate = true
      }
    },
    // 全选
    onAllSelected() {
      var thiz = this
      if (thiz.allCheck) {
        var allSelected = []
        thiz.postInfo.pics.forEach((k) => {
          k.selectedPic = true
          allSelected.push(k.id)
        })
        thiz.selectedLength = allSelected.length
        if (allSelected.length > 1) {
          thiz.hasLength = true
        }
        thiz.selected = allSelected
        if (thiz.selected.length == thiz.postInfo.pics.length) {
          thiz.allCheck = true
          thiz.isIndeterminate = false
        }
      } else {
        thiz.selected = []
        thiz.isIndeterminate = false
        thiz.selectedLength = 0
        thiz.allCheck = false
        thiz.postInfo.pics.forEach((k) => {
          k.selectedPic = false
        })
      }
    },
    onLargeLeft() {
      if (this.swiperIndex == 0) {
        this.swiperIndex = this.swiperIndex + 0
      } else if (this.swiperIndex == 2) {
        this.swiperIndex = this.swiperIndex - 1
      } else if (this.swiperIndex == 1) {
        this.swiperIndex = this.swiperIndex - 1
      } else {
        this.swiperIndex = this.swiperIndex - 2
      }
      this.$nextTick(function () {
        this.calculate()
      })
    },
    onLargeRight() {
      console.log('jkk', this.swiperIndex)
      if (this.swiperIndex == this.postInfo.pics.length - 2) {
        this.swiperIndex = this.swiperIndex + 1
      } else if (this.swiperIndex == this.postInfo.pics.length - 3) {
        this.swiperIndex = this.swiperIndex + 1
      } else if (this.swiperIndex == this.postInfo.pics.length - 1) {
        this.swiperIndex = this.swiperIndex + 0
      } else {
        this.swiperIndex = this.swiperIndex + 2
      }
      this.$nextTick(function () {
        this.calculate()
      })
    },
    // 点击大图，查看图片详情
    onLookPic(navIndex) {
      console.log('8989')
      this.swiperIndex = navIndex
      this.$nextTick(function () {
        this.calculate()
      })
      // this.next()
    },
    // 计算移动距离
    calculate() {
      var toMiddleOffset = 0
      console.log('mx19', this.swiperIndex)
      for (var i = 0; i < this.swiperIndex; i++) {
        toMiddleOffset += this.$refs.swiperpic[i].clientWidth + 10
      }
      toMiddleOffset += this.$refs.swiperpic[this.swiperIndex].clientWidth / 2
      console.log('toMiddleOffset', toMiddleOffset)
      var offset = 479 - toMiddleOffset
      if (offset > 0) {
        offset = 0
      }
      var allWidth = 0
      for (var k = 0; k < this.postInfo.pics.length; k++) {
        allWidth += this.$refs.swiperpic[k].clientWidth + 10
      }
      if (offset <= 958 - allWidth) {
        offset = 958 - allWidth
      }
      this.transformDistance = offset / 10
      console.log('toMiddleOffset', this.transformDistance)
    },
    // 点击小图列表
    onEnlarge(index) {
      var thiz = this
      thiz.transformlargeDistance = index * 90.9
      thiz.picIndex = index
    },
    // 收藏
    onCollect() {
      try {
        window.external.addFavorite(window.location, document.title)
      } catch (e) {
        try {
          window.sidebar.addPanel(window.location, document.title, '')
        } catch (e) {
          // sweetAlert("加入收藏失败，请使用Ctrl+D进行添加");
          this.dialogVisible = true
        }
      }
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 分享
    onGetShare() {
      console.log('466')
      this.$message('请点击右侧分享按钮进行分享')
    },
    onDownNumber(type) {
      var thiz = this
      console.log('sf', thiz.selected)
      if (thiz.selected.length == 0) {
        thiz.$message('请先勾选图片，然后进行操作')
        return
      }
      thiz.picIdsString = JSON.stringify(thiz.selected)
      if (type == 'car') {
        thiz.cartUrl = RD.pic().toCart(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.addCartPicForm.submit()
        })
      } else if (type == 'downs') {
        thiz.ssPicUrl = RD.pic().ssImageBatch_URL(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.ssPicForm.submit()
        })
      } else if (type == 'downo') {
        thiz.ooPicUrl = RD.pic().oImageBatch_URL(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.ooPicForm.submit()
        })
      } else if (type == 'downm') {
        thiz.mmPicUrl = RD.pic().mmImageBatch_URL(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.mmPicForm.submit()
        })
      } else if (type == 'sign') {
        thiz.signPicUrl = RD.pic().signOut(thiz.selected)
        thiz.$nextTick(() => {
          thiz.$refs.signPicForm.submit()
        })
      }
    },
  },
}
</script>

<style scoped lang="less">
@media all and (max-width: 800px) {
  .postWhole {
    width: 750px;
    height: auto;
    .post-position {
      text-align: left;
      margin-left: 0.5rem;
      font-size: 1.6rem;
    }
    .post-content {
      background-color: #fff;
      margin: 1rem;
      margin-bottom: 0rem;
      .post-bigPic {
        margin: 0.5rem;
        .post-info {
          min-height: 15rem;
          .post-title {
            font-size: 2rem;
            text-align: left;
            padding-top: 2rem;
          }
          .post-other {
            text-align: left;
            margin-top: 1rem;
            font-size: 1.4rem;
            div {
              height: 2.5rem;
            }
          }
        }
        .post-pic {
          display: flex;
          height: 500px;
          .post-left {
            display: flex;
            height: 100%;
            width: 100%;
            margin: 0 auto;
            transition: all 500ms ease-in-out;
            flex-direction: row;
            .post-img {
              width: 0rem;
              height: 100%;
              margin: 0 auto;
              background: #000;
              transition: all 0.3s ease-in-out;
              position: relative;
              overflow: hidden;
              .only-img {
                width: 100%;
                height: 100%;
                background: no-repeat 50% / contain;
              }
            }
            .post-img.active {
              width: 100%;
              height: 100%;
            }
          }
          .post-right {
            width: 27%;
            text-align: left;
            padding-left: 2rem;
            background-color: #f5f5f5;
            .pic-title {
              font-size: 1.8rem;
            }
            .pic-size {
              font-size: 1.4rem;
              margin-top: 2rem;
              div {
                height: 3rem;
                line-height: 3rem;
              }
            }
            .pic-other {
              width: 85%;
              background-color: #fff;
              padding: 0 1rem;
              margin-top: 2rem;
              div {
                height: 3rem;
                line-height: 3rem;
              }
            }
            .pic-down {
              height: 5rem;
              margin-top: 2rem;
              .picdown {
                width: 92%;
                height: 3.5rem;
                line-height: 3.5rem;
                border-radius: 1rem;
                text-align: center;
                .el-button {
                  height: 100%;
                  width: 100%;
                }
              }
            }
            .pic-share {
              height: 5rem;
              display: flex;
              margin-top: 2rem;
              div {
                border: 1px solid #ccc;
                border-radius: 1rem;
                height: 3rem;
                width: 43%;
                text-align: center;
                line-height: 3rem;
                margin-right: 1rem;
                cursor: pointer;
              }
            }
          }
        }
        .pic-list {
          margin-top: 5rem;
          background-color: #e5e5e5;
          padding-bottom: 4rem;
          position: relative;
          .pic-select {
            height: 5rem;
            text-align: left;
            margin-left: 8.5rem;
            line-height: 5rem;
          }
          .pic-text {
            text-align: left;
          }
          .pic-swiper {
            overflow: hidden;
            width: 100rem;
            height: 22rem;
            margin: 0 auto;
            position: relative;
            .pic-show {
              position: absolute;
              width: auto;
              height: 100%;
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              transition: all 800ms ease-in-out;
              margin-top: 3rem;
              .pic-item {
                width: 25.2rem;
                height: 16rem;
                margin-right: 1rem;
                flex-shrink: 0;
                .bg-img {
                  width: 100%;
                  height: 100%;
                  background: no-repeat 50% / contain;
                }
                .active {
                  border: 1px solid red;
                  box-sizing: border-box;
                }
              }
            }
          }
          .pic-left {
            position: absolute;
            left: 0px;
            top: 2rem;
            height: 100%;
            .left-icon {
              position: absolute;
              transition: all 800ms ease-in-out;
              top: -2rem;
              left: 0;
              background-color: rgba(0, 0, 0, 0.4);
              height: 100%;
              width: 5rem;
              font-size: 2rem;
              cursor: pointer;
              z-index: 1;
              .el-icon-d-arrow-left {
                color: #fff;
                font-size: 5rem;
                margin-top: 13rem;
              }
            }
          }
          .pic-right {
            position: absolute;
            right: 5rem;
            top: 0rem;
            height: 100%;
            .right-icon {
              position: absolute;
              transition: all 800ms ease-in-out;
              right: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.2);
              height: 100%;
              width: 5rem;
              font-size: 2rem;
              cursor: pointer;
              z-index: 9999;
              .el-icon-d-arrow-right {
                color: #fff;
                font-size: 5rem;
                margin-top: 13rem;
              }
            }
          }
        }
        .post-text {
          min-height: 15rem;
          margin: 2rem 1rem;
          margin-bottom: 0rem;
          div {
            text-indent: 2rem;
            line-height: 3rem;
            font-size: 1.4rem;
            text-align: left;
          }
        }
      }
    }
    .video-detail {
      min-height: calc(100vh - 28.1rem);
      width: 750px;
      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        font-size: 3rem;
        height: 100px;
        line-height: 100px;
      }
      video {
        object-fit: contain;
        width: 100%;
        height: 100%;
        width: 750px;
        height: 421px;
      }
      .video-text {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.3rem;
        min-height: 4rem;
        padding-top: 1.5rem;
        padding-left: 0.5rem;
        line-height: 3rem;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
      }
      .video-btn {
        height: 50px;
        .v-b {
          width: 10rem;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.5rem;
          border-radius: 2rem;
          border: 1px solid #ccc;
          background-color: #409eff;
          color: #fff;
          cursor: pointer;
        }
      }
      .video-maintext {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        padding-left: 0.5rem;
        font-size: 2.2rem;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
        pre {
          text-align: left;
          white-space: pre-wrap;
          word-wrap: break-word;
          font-size: 1.8rem;
          line-height: 4.5rem;
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .postWhole {
    min-height: calc(100vh - 25.9rem);
    width: 120rem;
    margin: 0rem auto;
    border-top: 0.1rem solid #ccc;
    position: relative;
    background-color: #dedfe1;
    .post-position {
      text-align: left;
      margin-left: 0.5rem;
      font-size: 1.6rem;
    }
    .video-detail {
      min-height: calc(100vh - 28.1rem);
      width: 120rem;
      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
        font-size: 3rem;
        height: 10rem;
        line-height: 10rem;
      }
      video {
        object-fit: contain;
        width: 100%;
        height: 100%;
        width: 120rem;
        height: 72rem;
      }
      .video-text {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        display: flex;
        flex-wrap: wrap;
        font-size: 1.3rem;
        min-height: 4rem;
        padding-top: 1.5rem;
        padding-left: 0.5rem;
        line-height: 3rem;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
      }
      .video-btn {
        height: 5rem;
        .v-b {
          width: 10rem;
          height: 4rem;
          line-height: 4rem;
          font-size: 1.5rem;
          border-radius: 2rem;
          border: 1px solid #ccc;
          background-color: #409eff;
          color: #fff;
          cursor: pointer;
        }
      }
      .video-maintext {
        width: 100%;
        height: auto;
        margin: 2rem 0rem;
        padding-left: 0.5rem;
        font-size: 2.2rem;
        div {
          text-align: left;
          margin-right: 2.5rem;
        }
        pre {
          text-align: left;
          white-space: pre-wrap;
          word-wrap: break-word;
          font-size: 1.8rem;
          line-height: 4.5rem;
        }
      }
    }
    .post-content {
      background-color: #fff;
      margin: 1rem;
      margin-bottom: 0rem;
      .post-bigPic {
        margin: 0.5rem;
        .post-info {
          min-height: 15rem;
          .post-title {
            font-size: 2rem;
            text-align: left;
            padding-top: 2rem;
          }
          .post-other {
            text-align: left;
            margin-top: 1rem;
            font-size: 1.4rem;
            div {
              height: 2.5rem;
            }
          }
        }
        .post-pic {
          display: flex;
          height: 50rem;
          .post-left {
            display: flex;
            height: 100%;
            width: 100%;
            margin: 0 auto;
            transition: all 500ms ease-in-out;
            flex-direction: row;
            .post-img {
              width: 0rem;
              height: 100%;
              margin: 0 auto;
              background: #000;
              transition: all 0.3s ease-in-out;
              position: relative;
              overflow: hidden;
              .only-img {
                width: 100%;
                height: 100%;
                background: no-repeat 50% / contain;
              }
            }
            .post-img.active {
              width: 100%;
              height: 100%;
            }
          }
          .post-right {
            width: 27%;
            text-align: left;
            padding-left: 2rem;
            background-color: #f5f5f5;
            .pic-title {
              font-size: 1.8rem;
            }
            .pic-size {
              font-size: 1.4rem;
              margin-top: 2rem;
              div {
                height: 3rem;
                line-height: 3rem;
              }
            }
            .pic-other {
              width: 85%;
              background-color: #fff;
              padding: 0 1rem;
              margin-top: 2rem;
              div {
                height: 3rem;
                line-height: 3rem;
              }
            }
            .pic-down {
              height: 5rem;
              margin-top: 2rem;
              .picdown {
                width: 92%;
                height: 3.5rem;
                line-height: 3.5rem;
                border-radius: 1rem;
                text-align: center;
                .el-button {
                  width: 100%;
                  height: 100%;
                }
              }
            }
            .pic-share {
              height: 5rem;
              display: flex;
              margin-top: 2rem;
              div {
                border: 1px solid #ccc;
                border-radius: 1rem;
                height: 3rem;
                width: 43%;
                text-align: center;
                line-height: 3rem;
                margin-right: 1rem;
                cursor: pointer;
              }
            }
          }
        }
        .pic-list {
          margin-top: 5rem;
          background-color: #e5e5e5;
          padding-bottom: 4rem;
          position: relative;
          .pic-select {
            height: 5rem;
            text-align: left;
            margin-left: 8.5rem;
            line-height: 5rem;
          }
          .pic-text {
            text-align: left;
            margin-left: 9rem;
          }
          .pic-swiper {
            overflow: hidden;
            width: 100rem;
            height: 22rem;
            margin: 0 auto;
            position: relative;
            .pic-show {
              position: absolute;
              width: auto;
              height: 100%;
              display: flex;
              flex-wrap: nowrap;
              justify-content: flex-start;
              transition: all 800ms ease-in-out;
              margin-top: 3rem;
              .pic-item {
                width: 25.2rem;
                height: 18rem;
                margin-right: 1rem;
                flex-shrink: 0;
                position: relative;
                .el-checkbox {
                  text-align: left;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                }
                .bg-img {
                  margin-top: 2rem;
                  width: 100%;
                  height: 95%;
                  background: no-repeat 50% / contain;
                }
                .active {
                  border: 1px solid red;
                  box-sizing: border-box;
                }
              }
            }
          }
          .pic-left {
            position: absolute;
            left: 0px;
            top: 2rem;
            height: 100%;
            .left-icon {
              position: absolute;
              transition: all 800ms ease-in-out;
              top: -2rem;
              left: 0;
              background-color: rgba(0, 0, 0, 0.4);
              height: 100%;
              width: 5rem;
              font-size: 2rem;
              cursor: pointer;
              z-index: 1;
              .el-icon-d-arrow-left {
                color: #fff;
                font-size: 5rem;
                margin-top: 13rem;
              }
            }
          }
          .pic-right {
            position: absolute;
            right: 5rem;
            top: 0rem;
            height: 100%;
            .right-icon {
              position: absolute;
              transition: all 800ms ease-in-out;
              right: 0;
              left: 0;
              background-color: rgba(0, 0, 0, 0.2);
              height: 100%;
              width: 5rem;
              font-size: 2rem;
              cursor: pointer;
              z-index: 9999;
              .el-icon-d-arrow-right {
                color: #fff;
                font-size: 5rem;
                margin-top: 13rem;
              }
            }
          }
        }
        .post-text {
          min-height: 15rem;
          margin: 2rem 1rem;
          margin-bottom: 0rem;
          div {
            text-indent: 2rem;
            line-height: 3rem;
            font-size: 1.4rem;
            text-align: left;
          }
        }
      }
    }
  }
}
</style>
